import i18next from 'i18next';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import PermissionGuard from 'src/auth/guard/permission-guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const DashboardPage = lazy(() => import('src/pages/dashboard'));

// SURVEY DASHBOARD
const SurveyDashboardLayout = lazy(() => import('src/pages/survey-dashboard'));
const SurveyDashboardPage = lazy(() => import('src/pages/survey-dashboard/dashboard'));
const AnalysisPage = lazy(() => import('src/pages/survey-dashboard/analysis'));
const HeatmapPage = lazy(() => import('src/pages/survey-dashboard/heatmap'));
const FeedbackPage = lazy(() => import('src/pages/survey-dashboard/feedback'));
const SegmentsPage = lazy(() => import('src/pages/survey-dashboard/segments'));
const SegmentDashboardPage = lazy(() => import('src/pages/survey-dashboard/segment-dashboard'));
const DriverDashboardPage = lazy(() => import('src/pages/survey-dashboard/driver-dashboard'));

// CUSTOM SURVEY DASHBOARD
const CustomSurveyDashboardLayout = lazy(() => import('src/pages/custom-survey-dashboard'));
const CustomSurveyDashboardPage = lazy(() => import('src/pages/custom-survey-dashboard/dashboard'));
const CustomHeatmapPage = lazy(() => import('src/pages/custom-survey-dashboard/heatmap'));
const CustomFeedbackPage = lazy(() => import('src/pages/custom-survey-dashboard/feedback'));
const CustomSegmentsPage = lazy(() => import('src/pages/custom-survey-dashboard/segments'));
const CustomSegmentDashboardPage = lazy(
  () => import('src/pages/custom-survey-dashboard/segment-dashboard')
);

// SCHEDULES
const SurveyListPage = lazy(() => import('src/pages/survey/list'));
const SurveyCreatePage = lazy(() => import('src/pages/survey/new'));
const SurveyDetailPage = lazy(() => import('src/pages/survey/detail'));
const SurveyEditPage = lazy(() => import('src/pages/survey/edit'));

// ACTION
const ActionPlanPage = lazy(() => import('src/pages/action-plan/list'));
const ActionLibraryPage = lazy(() => import('src/pages/action-library'));
const MyActionsPage = lazy(() => import('src/pages/my-actions/list'));
const ActionCreatePage = lazy(() => import('src/pages/action/new'));
const ActionEditPage = lazy(() => import('src/pages/action/edit'));

// NEEDS
const NeedsListPage = lazy(() => import('src/pages/needs/list'));
const NeedsEditPage = lazy(() => import('src/pages/needs/edit'));

// --------------------------- COMPANY --------------------------- //
// EMPLOYEES
const EmployeeListPage = lazy(() => import('src/pages/employee/list'));
const EmployeeCreatePage = lazy(() => import('src/pages/employee/new'));
const EmployeeEditPage = lazy(() => import('src/pages/employee/edit'));

// ROLES
const RoleListPage = lazy(() => import('src/pages/role/list'));

// GROUPS
const GroupListPage = lazy(() => import('src/pages/group/list'));

// SEGMENTS
const SegmentListPage = lazy(() => import('src/pages/segment/list'));

// SETTINGS
const SettingPage = lazy(() => import('src/pages/setting'));

// --------------------------- SURVEY --------------------------- //
// DATA
const DataPage = lazy(() => import('src/pages/data'));

// MAILING
const MailingPage = lazy(() => import('src/pages/mailing'));

// TOPICS
const TopicListPage = lazy(() => import('src/pages/topic/list'));
const TopicCreatePage = lazy(() => import('src/pages/topic/new'));
const TopicEditPage = lazy(() => import('src/pages/topic/edit'));

// --------------------------- E-LEARNING --------------------------- //
const ElearningPage = lazy(() => import('src/pages/elearning'));

// --------------------------- SUBSIDIARIES DASHBOARD --------------------------- //
const SubsidiariesDashboardPage = lazy(() => import('src/pages/subsidiaries-dashboard'));

// NOTIFICATIONS
const NotificationsPage = lazy(() => import('src/pages/notifications'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <PermissionGuard
            hasContent
            route={['dashboard', 'participationrate']}
            type="R"
            title={i18next.t('errors.no_permission_to_access')}
            description=""
          >
            <DashboardPage />
          </PermissionGuard>
        ),
        index: true,
      },
      {
        path: 'survey-dashboard',
        element: (
          <SurveyDashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </SurveyDashboardLayout>
        ),
        children: [
          {
            element: (
              <PermissionGuard
                hasContent
                route={['dashboard', 'participationrate']}
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <SurveyDashboardPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'analysis',
            element: (
              <PermissionGuard
                hasContent
                route={['dashboard', 'participationrate']}
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <AnalysisPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'heatmap',
            element: (
              <PermissionGuard
                hasContent
                route="dashboard"
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <HeatmapPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'feedback',
            element: (
              <PermissionGuard
                hasContent
                route="feedback"
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <FeedbackPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'segments',
            element: (
              <PermissionGuard
                hasContent
                route={['dashboard', 'participationrate']}
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <SegmentsPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'segment-dashboard',
            element: (
              <PermissionGuard
                hasContent
                route="dashboard"
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <SegmentDashboardPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'driver-dashboard',
            element: (
              <PermissionGuard
                hasContent
                route="dashboard"
                type="R"
                title={i18next.t('errors.no_permission_to_access')}
                description=""
              >
                <DriverDashboardPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'custom-survey-dashboard',
        element: (
          <CustomSurveyDashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </CustomSurveyDashboardLayout>
        ),
        children: [
          {
            element: (
              <PermissionGuard hasContent route="dashboard" type="R">
                <CustomSurveyDashboardPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'heatmap',
            element: (
              <PermissionGuard hasContent route="dashboard" type="R">
                <CustomHeatmapPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'feedback',
            element: (
              <PermissionGuard hasContent route="feedback" type="R">
                <CustomFeedbackPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'segments',
            element: (
              <PermissionGuard hasContent route="dashboard" type="R">
                <CustomSegmentsPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'segment-dashboard',
            element: (
              <PermissionGuard hasContent route="dashboard" type="R">
                <CustomSegmentDashboardPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'schedules',
        children: [
          {
            element: (
              <PermissionGuard hasContent route="schedules" type="R">
                <SurveyListPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'new',
            element: (
              <PermissionGuard hasContent route="schedules" type="W">
                <SurveyCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: 'detail',
            element: (
              <PermissionGuard hasContent route="schedules" type="W">
                <SurveyDetailPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'edit',
            element: (
              <PermissionGuard hasContent route="schedules" type="W">
                <SurveyEditPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'actions',
        children: [
          {
            path: 'action-plan',
            element: (
              <PermissionGuard hasContent route="actionplan" type="R">
                <ActionPlanPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'library',
            element: (
              <PermissionGuard hasContent route="actionslibrary" type="R">
                <ActionLibraryPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'my-actions',
            element: (
              <PermissionGuard hasContent route="myactions" type="R">
                <MyActionsPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <PermissionGuard hasContent route="actions" type="W">
                <ActionCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <PermissionGuard hasContent route="actions" type="W">
                <ActionEditPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'needs',
        element: (
          <PermissionGuard hasContent route="needs" type="R">
            <NeedsListPage />
          </PermissionGuard>
        ),
      },
      {
        path: 'needs/edit',
        element: (
          <PermissionGuard hasContent route="needs" type="W">
            <NeedsEditPage />
          </PermissionGuard>
        ),
      },
      {
        path: 'company',
        children: [
          {
            path: 'employees',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="employeedirectory" type="R">
                    <EmployeeListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent route="employeedirectory" type="W">
                    <EmployeeCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'edit/:id',
                element: (
                  <PermissionGuard hasContent route="employeedirectory" type="W">
                    <EmployeeEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'roles',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="Roles" type="R">
                    <RoleListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: 'groups',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="Groupes" type="R">
                    <GroupListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: 'segments',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="segmentsettings" type="R">
                    <SegmentListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: 'security',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="security" type="R">
                    <SettingPage />
                  </PermissionGuard>
                ),
                index: true,
              },
            ],
          },
        ],
      },
      {
        path: 'survey',
        children: [
          {
            path: 'data',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="data" type="R">
                    <DataPage />
                  </PermissionGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: 'mailing',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="textes" type="R">
                    <MailingPage />
                  </PermissionGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: 'topics',
            children: [
              {
                element: (
                  <PermissionGuard hasContent route="surveytypes" type="R">
                    <TopicListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent route="surveytypes" type="W">
                    <TopicCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'edit/:id',
                element: (
                  <PermissionGuard hasContent route="surveytypes" type="W">
                    <TopicEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'elearning',
        children: [
          {
            element: (
              <PermissionGuard hasContent route="e-learning" type="R">
                <ElearningPage />
              </PermissionGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: 'subsidiaries-dashboard',
        children: [
          {
            element: <SubsidiariesDashboardPage />,
            index: true,
          },
        ],
      },
      {
        path: 'notifications',
        element: <NotificationsPage />,
      },
    ],
  },
];
