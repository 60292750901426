import useSWR from 'swr';
import { useMemo } from 'react';

import axiosInstance, { fetcher } from 'src/utils/axios';

import { useAuthContext } from 'src/auth/hooks';
import { objectToQueryString } from 'src/helpers/survey-dashboard';

// -------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

export function useGetSurveyDetails(id: number | null) {
  const URL = id ? `/dashboard/survey-details/${id}` : null;

  const { data: survey, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      survey,
      isLoading,
      isEmpty: !isLoading && !survey,
      error,
      isValidating,
      mutate,
    }),
    [survey, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetSurveyStats(
  roundId: number | null,
  segments_query = {},
  include_children = true,
  min_participants = 0
) {
  let URL = null;

  if (roundId) {
    URL = `/dashboard/segmentsdashboard?roundId=${roundId}&${objectToQueryString(
      segments_query
    )}&include_children=${include_children}&min_participants=${min_participants}`;
  }

  const { data: stats, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      stats,
      isLoading,
      isEmpty: !isLoading && !stats,
      error,
      isValidating,
      mutate,
    }),
    [stats, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetParticipationStats(
  roundId: number | null,
  segments_query = {},
  include_children = true,
  min_participants = 0
) {
  let URL = null;

  if (roundId) {
    URL = `/dashboard/participation?roundId=${roundId}&${objectToQueryString(
      segments_query
    )}&include_children=${include_children}&min_participants=${min_participants}`;
  }

  const { data: stats, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      stats,
      isLoading,
      isEmpty: !isLoading && !stats,
      error,
      isValidating,
      mutate,
    }),
    [stats, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetGallupStats(
  roundId: number | null,
  segments_query = {},
  include_children = true
) {
  let URL = null;

  if (roundId) {
    URL = `/dashboard/gallup-stats?roundId=${roundId}&${objectToQueryString(
      segments_query
    )}&include_children=${include_children}`;
  }

  const { data: stats, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      stats,
      isLoading,
      isEmpty: !isLoading && !stats,
      error,
      isValidating,
      mutate,
    }),
    [stats, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetEngagementByGroup(
  planningId: number | null,
  roundId: number | null,
  engagementMethod: 1 | 2
) {
  let URL = null;

  if (roundId && planningId) {
    URL = `/dashboard/engagementbygroup?planningId=${planningId}&roundId=${roundId}&engagementMethod=${engagementMethod}`;
  }

  const { data: stats, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      stats,
      isLoading,
      isEmpty: !isLoading && !stats,
      error,
      isValidating,
      mutate,
    }),
    [stats, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetDriverWeights() {
  const { user } = useAuthContext();

  const URL = user?.companyId ? `/companydriverweights?companyId=${user?.companyId}` : null;

  const {
    data: driverWeights,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      driverWeights,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [driverWeights, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetCustomDriverWeights() {
  const { user } = useAuthContext();

  const URL = user?.companyId ? `/companycustomdriverweights?companyId=${user?.companyId}` : null;

  const {
    data: customDriverWeights,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      customDriverWeights,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [customDriverWeights, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetAllClasses() {
  const URL = `/segments/allclasses`;

  const { data: classes, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      classes,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [classes, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetAllLocations() {
  const URL = `/segments/allLocations`;

  const { data: locations, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      locations,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [locations, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function addPyramidOfNeedsDrivers(data: any) {
  const res = await axiosInstance.post(`/pyramidofneeds/drivers`, data);
  return res.data;
}

export async function getSubdriverStatsByDriverId(data: any) {
  const res = await axiosInstance.post(`/dashboard/subdriverStatsByDriverId`, data);
  return res.data;
}
