import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import { NavItemBaseProps } from 'src/components/mega-menu';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const { user } = useAuthContext();

  const permissions = useMemo(() => user?.permissions || [], [user]);

  const { showSurveyMenu, survey } = useSelector((state: any) => state.surveyDashboard);

  const mainMenu = useMemo(
    () => [
      {
        subheader: '',
        items: [
          // DASHBOARD
          {
            title: t('menu.dashboard'),
            path: paths.root,
            permission: { route: 'dashboard', type: 'R' },
            icon: <Iconify icon="ic:baseline-dashboard" />,
          },
          // SCHEDULES
          {
            title: t('menu.surveys.surveys'),
            path: paths.schedules.root,
            icon: <Iconify icon="ri:survey-line" />,
            children: [
              {
                title: t('menu.surveys.my_surveys'),
                path: paths.schedules.list,
                permission: { route: 'schedules', type: 'R' },
                icon: <Iconify icon="material-symbols:list" />,
              },
              {
                title: t('menu.surveys.new_survey'),
                path: paths.schedules.new,
                permission: { route: 'schedules', type: 'W' },
                icon: <Iconify icon="material-symbols:add" />,
              },
            ],
          },
          // ACTIONS
          {
            title: t('menu.act.act'),
            path: paths.actions.root,
            icon: <Iconify icon="material-symbols:flight-takeoff" />,
            children: [
              {
                title: t('menu.act.action_plan'),
                path: paths.actions.actionPlan,
                permission: { route: 'actionplan', type: 'R' },
                icon: <Iconify icon="material-symbols:tab-group-outline" />,
              },
              {
                title: t('menu.act.library'),
                path: paths.actions.library,
                permission: { route: 'actionslibrary', type: 'R' },
                icon: <Iconify icon="heroicons:building-library-20-solid" />,
              },
              {
                title: t('menu.act.my_actions'),
                path: paths.actions.myActions,
                permission: { route: 'myactions', type: 'R' },
                icon: <Iconify icon="clarity:tasks-solid" />,
              },
            ],
          },
          // NEEDS
          {
            title: t('menu.knowledge_center'),
            path: paths.needs.root,
            permission: { route: 'needs', type: 'R' },
            icon: <Iconify icon="material-symbols:psychology" />,
          },
          // USER
          /* {
            title: t('user'),
            path: paths.user.root,
            icon: ICONS.user,
            children: [
              { title: t('profile'), path: paths.user.root },
              { title: t('cards'), path: paths.user.cards },
              { title: t('list'), path: paths.user.list },
              { title: t('create'), path: paths.user.new },
              { title: t('edit'), path: paths.user.demo.edit },
              { title: t('account'), path: paths.user.account },
            ],
          }, */
        ],
      },
      // ADMINISTRATION
      // ----------------------------------------------------------------------
      {
        subheader: t('menu.admin.settings'),
        items: [
          // COMPANY
          {
            title: t('menu.admin.company.company'),
            path: `/company`,
            icon: <Iconify icon="mdi:company" />,
            children: [
              {
                title: t('menu.admin.company.employees'),
                path: paths.company.employees.root,
                permission: { route: 'employeedirectory', type: 'R' },
                icon: <Iconify icon="mdi:users" />,
              },
              {
                title: t('menu.admin.company.groups'),
                path: paths.company.groups.root,
                permission: { route: 'Groupes', type: 'R' },
                icon: <Iconify icon="material-symbols:account-balance" />,
              },
              {
                title: t('menu.admin.company.roles'),
                path: paths.company.roles.root,
                permission: { route: 'Roles', type: 'R' },
                icon: <Iconify icon="material-symbols:lock" />,
              },
              {
                title: t('menu.admin.company.segments'),
                path: paths.company.segments.root,
                permission: { route: 'segmentsettings', type: 'R' },
                icon: <Iconify icon="material-symbols:pie-chart" />,
              },
              {
                title: t('menu.admin.company.security'),
                path: paths.company.security.root,
                permission: { route: 'security', type: 'R' },
                icon: <Iconify icon="material-symbols:security" />,
              },
            ],
          },
          // SURVEY
          {
            title: t('menu.admin.survey.survey'),
            path: `/survey`,
            icon: <Iconify icon="tdesign:task-setting" />,
            children: [
              {
                title: t('menu.admin.survey.data'),
                path: paths.survey.data.root,
                permission: { route: 'data', type: 'R' },
                icon: <Iconify icon="material-symbols:storage" />,
              },
              {
                title: t('menu.admin.survey.mailing'),
                path: paths.survey.mailing.root,
                permission: { route: 'textes', type: 'R' },
                icon: <Iconify icon="material-symbols:topic" />,
              },
              {
                title: t('menu.admin.survey.topics'),
                path: paths.survey.topics.root,
                permission: { route: 'surveytypes', type: 'R' },
                icon: <Iconify icon="ic:baseline-question-answer" />,
              },
            ],
          },
        ],
      },
      // LMS
      // ----------------------------------------------------------------------
      {
        subheader: 'LMS',
        items: [
          {
            title: t('learning.elearning_content'),
            path: paths.elearning.root,
            permission: { route: 'e-learning', type: 'R' },
            icon: <Iconify icon="fa6-solid:graduation-cap" />,
          },
        ],
      },
    ],
    [t]
  ) as { subheader: string; items: NavItemBaseProps[] }[];

  const surveyDashboardMenu = useMemo(
    () => [
      {
        subheader: '',
        surveyMenu: true,
        items: [
          {
            title: t('menu.dashboard'),
            path: paths.surveyDashboard.root,
            icon: <Iconify icon="fa:tachometer" />,
            //
          },
          {
            title: t('survey_menu.analysis'),
            path: paths.surveyDashboard.analysis,
            icon: <Iconify icon="solar:chart-bold-duotone" />,
            //
          },
          {
            title: t('survey_menu.heatmap'),
            path: paths.surveyDashboard.heatmap,
            icon: <Iconify icon="fa:fire" />,
          },
          {
            title: t('survey_menu.feedback'),
            path: paths.surveyDashboard.feedback,
            icon: <Iconify icon="fa-solid:comment" />,
          },
          {
            title: t('survey_menu.segments'),
            path: paths.surveyDashboard.segments,
            icon: <Iconify icon="fa:pie-chart" />,
          },
        ],
      },
    ],
    [t]
  );

  const customSurveyDashboardMenu = useMemo(
    () => [
      {
        subheader: '',
        surveyMenu: true,
        items: [
          {
            title: t('menu.dashboard'),
            path: paths.customSurveyDashboard.root,
            icon: <Iconify icon="fa:tachometer" />,
            //
          },
          {
            title: t('survey_menu.heatmap'),
            path: paths.customSurveyDashboard.heatmap,
            icon: <Iconify icon="fa:fire" />,
          },
          {
            title: t('survey_menu.feedback'),
            path: paths.customSurveyDashboard.feedback,
            icon: <Iconify icon="fa-solid:comment" />,
          },
          {
            title: t('survey_menu.segments'),
            path: paths.customSurveyDashboard.segments,
            icon: <Iconify icon="fa:pie-chart" />,
          },
        ],
      },
    ],
    [t]
  );

  // show survey menu if survey is selected
  if (showSurveyMenu) {
    // change menu based on survey type (engagement or custom)
    if (survey?.isEngagement) {
      return surveyDashboardMenu;
    }
    return customSurveyDashboardMenu;
  }

  // filter out items that don't have permission
  let filteredMenu = mainMenu.map((group) => {
    let items = group.items.map((item) => {
      // always show the dashboard item
      if (item.path === paths.root) {
        return item;
      }

      if (!item.children) {
        const hasPermission = permissions.some(
          (permission: any) =>
            permission?.permission?.route === item.permission?.route &&
            permission?.permission?.type === item.permission?.type
        );

        return hasPermission ? item : { ...item, disabled: true };
      }

      const children = item.children.filter((child) =>
        permissions.some(
          (permission: any) =>
            permission?.permission?.route === child.permission?.route &&
            permission?.permission?.type === child.permission?.type
        )
      );

      return children.length > 0
        ? { ...item, children }
        : { ...item, children: [], disabled: true };
    });

    items = items.filter(
      (item) => !item?.children || (item?.children && item?.children?.length > 0)
    );

    items = items.filter((item) => !(!item?.children && item?.disabled));

    // filter null items
    // group.items = items.filter((item) => item);

    return { ...group, items };
  });

  // show the subsidiaries dashboard if the company is Attijari and the authenticated user has RH, admin or Executive role
  // if (
  //   user?.companyName?.toLowerCase() === 'attijariwafa bank' &&
  //   (user?.roleId === 1 || user?.roleId === 4 || user?.roleId === 5)
  // ) {
  //   filteredMenu.push({
  //     subheader: t('common.subsidiaries'),
  //     items: [
  //       {
  //         title: t('common.subsidiaries_dashboard'),
  //         path: paths.subsidiaries.dashboard,
  //         icon: <Iconify icon="fa:pie-chart" />,
  //       },
  //     ],
  //   });
  // }

  filteredMenu = filteredMenu.filter((group) => group.items.length > 0);

  return filteredMenu;
}
