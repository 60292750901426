import { isNaN } from 'lodash';

import { alpha } from '@mui/system';

import i18n from 'src/locales/i18n';
import { error, success, warning } from 'src/theme/palette';

// transform a JavaScript object to a URL query string
export function objectToQueryString(obj: any) {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      params.append(key, obj[key]);
    }
  });

  return params.toString();
}

export function getNPSStats(promoters: number, passifs: number, detractors: number, _alpha = 100) {
  if (!promoters && !passifs && !detractors) {
    return {
      promoters_percent: 0,
      detractors_percent: 0,
      passifs_percent: 0,
      nps: 0,
    };
  }

  const enps_votes = promoters + passifs + detractors;

  const promoters_percent = isNaN(Math.round((promoters / enps_votes) * 100 * _alpha) / _alpha)
    ? 0
    : Math.round((promoters / enps_votes) * 100 * _alpha) / _alpha;

  const passifs_percent = isNaN(Math.round((passifs / enps_votes) * 100 * _alpha) / _alpha)
    ? 0
    : Math.round((passifs / enps_votes) * 100 * _alpha) / _alpha;

  let detractors_percent =
    100 -
    (isNaN(Math.round((promoters_percent + passifs_percent) * _alpha) / _alpha)
      ? 0
      : Math.round((promoters_percent + passifs_percent) * _alpha) / _alpha);

  detractors_percent = Math.round(detractors_percent * _alpha) / _alpha;

  const nps = Math.round(promoters_percent - detractors_percent);

  return {
    promoters_percent,
    detractors_percent,
    passifs_percent,
    nps,
  };
}

/* ------------------------------------------------------------------------------------*
| gets engagement score based on method 2                                              |
| i.e weighed average of score of all drivers                                          |
*------------------------------------------------------------------------------------ */
export function computeEngagementScore(
  drivers: any[],
  driver_weights: any[],
  custom_driver_weights: any[]
) {
  let sum_score = 0;
  let sum_coefficients = 0;

  drivers.forEach((driver) => {
    if (!driver.custom) {
      driver_weights.forEach((dw) => {
        if (dw.id === driver.id) {
          driver.wavg = driver.avg * (dw.company_driver_weight?.weight || 1);
          sum_score += driver.wavg;
          sum_coefficients += dw.company_driver_weight?.weight || 1;
        }
      });
    }
  });

  drivers.forEach((driver) => {
    if (driver.custom) {
      custom_driver_weights.forEach((dw) => {
        if (dw.id === driver.id) {
          driver.wavg = driver.avg * (dw.company_driver_weight?.weight || 1);
          sum_score += driver.wavg;
          sum_coefficients += dw.company_driver_weight?.weight || 1;
        }
      });
    }
  });

  return sum_score / sum_coefficients;
}

// Used for enagement distribution chart (bar histogram)
export function getEngagementFrequency(
  engagement_distribution: any[],
  participants: number
): number[] {
  const data = [];
  for (let i = 0; i <= 10; i += 1) {
    const r = engagement_distribution.find((e) => e.score === i);
    if (r) {
      data.push(Math.round((r.frequency / participants) * 100));
    } else {
      data.push(0);
    }
  }

  return data;
}

export function getScoreHistoryChartSubtitle(data: any[]) {
  if (data?.length > 1) {
    let diff = data[data.length - 1].y - data[data.length - 2].y;
    diff = Number((Math.round(diff * 100) / 100).toFixed(2));

    if (diff > 0) {
      return `${i18n.t('dashboard.increase_by')} ${diff} ${i18n.t('since')} ${data[data.length - 2]
        ?.x}`;
    }

    if (diff < 0) {
      return `${i18n.t('dashboard.decrease_by')} ${diff} ${i18n.t('since')} ${data[data.length - 2]
        ?.x}`;
    }

    return `${i18n.t('dashboard.no_change_since')} ${data[data.length - 2]?.x}`;
  }

  return `${i18n.t('dashboard.no_change_since')} ${data[0]?.x}`;
}

export function getMoodColor(mood: number) {
  switch (mood) {
    case 1:
      return error.main;
    case 2:
      return warning.main;
    case 3:
      return warning.light;
    case 4:
      return success.light;
    case 5:
      return success.main;
    default:
      return '';
  }
}

export function getMoodLabel(mood: number) {
  switch (mood) {
    case 1:
      return i18n.t('dashboard.mood_labels.very_unhappy');
    case 2:
      return i18n.t('dashboard.mood_labels.unhappy');
    case 3:
      return i18n.t('dashboard.mood_labels.neutral');
    case 4:
      return i18n.t('dashboard.mood_labels.happy');
    case 5:
      return i18n.t('dashboard.mood_labels.very_happy');
    default:
      return i18n.t('dashboard.mood_labels.neutral');
  }
}

export const getNeedIcon = (id: number) => {
  switch (id) {
    case 1:
      return 'material-symbols:coffee';
    case 2:
      return 'fa-solid:male';
    case 3:
      return 'fa-solid:user-friends';
    case 4:
      return 'mingcute:tree-fill';
    default:
      return 'ph:wrench-fill';
  }
};

export function getScoreColor(
  score: number,
  score_distribution: any,
  theme: 'light' | 'dark',
  type: 'sx' | 'color' = 'sx'
) {
  // dark mode
  if (theme === 'dark') {
    if (isNaN(Number(score))) return type === 'sx' ? 'error.dark' : error.dark;

    if (Number(score) <= score_distribution.bad[1]) {
      return type === 'sx' ? 'error.main' : alpha(error.main, 0.5);
    }

    if (Number(score) <= score_distribution.average[1]) {
      return type === 'sx' ? 'warning.main' : alpha(warning.main, 0.5);
    }

    return type === 'sx' ? 'success.main' : alpha(success.main, 0.5);
  }

  // light mode

  if (isNaN(Number(score))) return type === 'sx' ? 'error.lighter' : error.lighter;

  if (Number(score) <= score_distribution.bad[1]) {
    return type === 'sx' ? 'error.lighter' : error.lighter;
  }

  if (Number(score) <= score_distribution.average[1]) {
    return type === 'sx' ? 'warning.lighter' : warning.lighter;
  }

  return type === 'sx' ? 'success.lighter' : success.lighter;
}

export function getParticipationColor(rate: number) {
  if (rate >= 70) {
    return 'success';
  }
  if (rate >= 40) {
    return 'warning';
  }

  return 'error';
}

export function getParticipationColorSX(rate: number) {
  if (rate >= 70) {
    return success.light;
  }
  if (rate >= 40) {
    return warning.light;
  }

  return error.light;
}

export function getPromoterPercentColor(rate: number) {
  if (rate >= 70) {
    return success.light;
  }
  if (rate >= 40) {
    return warning.light;
  }

  return error.light;
}

export function getDetractorPercentColor(rate: number) {
  return getPromoterPercentColor(100 - rate);
}

export function groupDriversByNeed(drivers: any[]) {
  let needs = drivers.reduce(
    (d, item) => {
      try {
        let current = d.hash[item.need];

        if (!current) {
          d.hash[item.need] = {
            need: item.need,
            need_level: item.need_level,
            drivers: [],
          };

          current = d.hash[item.need];

          d.arr.push(current);
        }

        current.drivers.push({
          avg: item.avg,
        });

        return d;
      } catch (err) {
        return null;
      }
    },
    { hash: {}, arr: [] }
  ).arr;

  needs = needs
    .map((need: any) => {
      const sum = need.drivers.reduce((a: any, b: any) => a + Number(b.avg), 0);
      const avg = sum / need.drivers.length;
      need.avg = avg;
      return need;
    })
    .sort((a: any, b: any) => a.need_level - b.need_level)
    .filter((need: any) => !!need.need);

  return needs;
}

export function computeEngagementByGroup(
  engagement: any[],
  nps: any[],
  driverWeights: any[],
  customDriverWeights: any[]
) {
  const driversBySegments = groupDriversBySegment2(engagement) as any[];
  const engagementByGroup = [] as any[];

  driversBySegments.forEach((group) => {
    const score = computeEngagementScore(group.drivers, driverWeights, customDriverWeights);
    let temp = nps.find((e) => e.companygroupid === group.id);
    if (temp === undefined) temp = { promoters: 0, passifs: 0, detractors: 0 };

    engagementByGroup.push({
      id: group.id,
      name: group.name,
      code: group.code,
      type: group.type,
      level: group.level,
      parentGroupId: group.parentGroupId,
      parentName: group.parentName,
      engagement: score || 0,
      show_children: 0,
      promoters: temp.promoters,
      passifs: temp.passifs,
      detractors: temp.detractors,
    });
  });
  return engagementByGroup;
}

export function groupDriversBySegment2(groups: any[]): any[] {
  try {
    return groups.reduce(
      (g, item) => {
        try {
          let current = g.hash[item.companyGroupId];

          if (!current) {
            g.hash[item.companyGroupId] = {
              id: item.companyGroupId,
              name: item.group_name,
              code: item.code,
              type: item.type,
              level: item.level,
              parentGroupId: item.parent_group_id,
              parentName: item.parent,
              drivers: [],
            };

            current = g.hash[item.companyGroupId];

            g.arr.push(current);
          }

          current.drivers.push({
            id: item.categoryid,
            driver: item.name,
            avg: item.avg,
            custom: item.custom,
          });

          return g;
        } catch (err) {
          return null;
        }
      },
      { hash: {}, arr: [] }
    ).arr;
  } catch (err) {
    console.error(err);
    return [];
  }
}

function calculateNodeValues(_group: any) {
  let child_score = 0;
  let child_participants = 0;
  let child_recipients = 0;
  let child_promoters = 0;
  let child_passifs = 0;
  let child_detractors = 0;

  const recursive = (group: any) => {
    if (group.engagement != null && group.engagement !== -1) {
      const participants = Math.round((group.participation * group.recipients) / 100) || 0;

      child_score += participants * parseFloat(group.engagement || 0);
      child_participants += participants;

      child_recipients += group.recipients;
      child_promoters += group.promoters;
      child_passifs += group.passifs;
      child_detractors += group.detractors;
    }

    if (group.nodes.length > 0) {
      group.nodes.forEach((node: any) => {
        recursive(node);
      });
    }
  };
  recursive(_group);

  return {
    weighed_score: (child_score / child_participants).toFixed(1),
    child_participants,
    child_recipients,
    child_promoters,
    child_passifs,
    child_detractors,
  };
}

export function calculateAgregatedEngagementScore(groups: any[]) {
  groups.forEach((group) => {
    if (group.nodes.length > 0) {
      const results = calculateNodeValues(group);
      group.weighed_score = results.weighed_score;
      group.child_participants = results.child_participants;
      group.child_recipients = results.child_recipients;
      group.child_promoters = results.child_promoters;
      group.child_passifs = results.child_passifs;
      group.child_detractors = results.child_detractors;

      calculateAgregatedEngagementScore(group.nodes);
    }
  });
}

export const getLowestAndHighestImportantSegment = (segments: any[]) => {
  if (!segments || !segments.length) {
    return {
      lowestSegment: null,
      highestSegment: null,
    };
  }

  // --- calculate weighted score
  // The value of 𝛼 determines the importance of the engagement score relative to the population proportion.
  // For example, 𝛼 = 0.5 gives equal weight to both factors.
  // we calculate the value of 𝛼 based on the difference between the minimum participation and tha maximum participation.
  const minParticipation = Math.min(...segments.map((s) => s.participation));
  const maxParticipation = Math.max(...segments.map((s) => s.participation));
  const diff = maxParticipation - minParticipation;
  const _alpha = 0.7 + (diff / 100) * 0.3;

  const minScore = Math.min(...segments.map((s) => s.score));
  const maxScore = Math.max(...segments.map((s) => s.score));

  segments.forEach((s) => {
    const normalizedScore = (s.score - minScore) / (maxScore - minScore);
    const normalizedParticipation =
      (s.participation - minParticipation) / (maxParticipation - minParticipation);

    s.weightedLowestScore = _alpha * normalizedScore + (1 - _alpha) * (1 - normalizedParticipation);
    s.weightedHighestScore = _alpha * normalizedScore + (1 - _alpha) * normalizedParticipation;
  });

  // get group with lowest weighted score
  const lowestSegment = segments.reduce((acc, s) =>
    s.weightedLowestScore < acc.weightedLowestScore ? s : acc
  );
  // get group with highest weighted score
  const highestSegment = segments.reduce((acc, s) =>
    s.weightedHighestScore > acc.weightedHighestScore ? s : acc
  );

  return { lowestSegment, highestSegment };
};
