import { useState, useEffect } from 'react';

import api from 'src/api';

export default function useHasPermission(route: string, type: 'R' | 'W') {
  const [hasAccess, setHasAccess] = useState(null);

  const { data, isLoading } = api.auth.useGetHasPermission(route);

  useEffect(() => {
    if (isLoading || !data) return;

    const { has_permission, has_permission_write } = data;

    if (type === 'W') {
      setHasAccess(has_permission_write);
    }
    if (type === 'R') {
      setHasAccess(has_permission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, route, type]);

  return hasAccess;
}
