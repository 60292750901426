import * as XLSX from 'xlsx';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import { useRef, useMemo, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Stack, Typography, LinearProgress } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import api from 'src/api';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { error, primary, success } from 'src/theme/palette';
import XLImage from 'src/assets/images/xlimport.png';
import { LANGUAGE_SUPPORT, IMPORT_EMPLOYEE_TEMPLATE } from 'src/constants/common';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

const HEADERS = [
  'FIRST_NAME',
  'LAST_NAME',
  'EMAIL',
  'GENDER',
  'DATE_OF_BIRTH',
  'EMPLOYED_SINCE',
  'GROUP',
  'MANAGER_EMAIL',
  'POSITION',
  'IS_MANAGER',
  'EMPLOYEE_ID',
  'MANAGER_EMPLOYEE_ID',
  'JOB_CODE',
  'LOCALE',
  'CLASS',
  'LOCATION',
  'SUBSIDIARY',
];

type Props = {
  open: boolean;
  onClose: () => void;
  notification: any;
};

export default function ImportEmployeesDialog({ open, notification, onClose }: Props) {
  const { t } = useTranslate();

  const [importResults, setImportResults] = useState({
    status: '',
    message: '',
  });
  const [nbReceivedEmployees, setNbReceivedEmployees] = useState(0);
  const [nbInsertedEmployees, setNbInsertedEmployees] = useState(0);
  const [nbUpdatedEmployees, setNbUpdatedEmployees] = useState(0);

  const [logsData, setLogsData] = useState<any[]>([]);
  const [invalidGroups, setInvalidGroups] = useState<any[]>([]);

  const { user } = useAuthContext();

  useEffect(() => {
    if (open) {
      const payload = notification?.payload;

      if (!payload?.err) {
        const _nbReceivedEmployees = payload?.received_records;
        const _nbInsertedEmployees = payload?.inserted_records;
        const _nbUpdatedEmployees = payload?.updated_records;
        const _nbProcessedEmployees = _nbInsertedEmployees + _nbUpdatedEmployees;

        if (_nbProcessedEmployees < _nbReceivedEmployees) {
          setImportResults({
            ...importResults,
            status: 'COMPLETED_WITH_ERRORS',
            message: `${_nbReceivedEmployees - _nbProcessedEmployees}  ${t(
              'import_employees.employees_ignored'
            )}`,
          });

          setLogsData(payload?.invalid_records);
          setInvalidGroups(payload?.invalid_group_codes);
        } else {
          setImportResults({
            ...importResults,
            status: 'SUCCESS',
            message: 'Import successful',
          });
        }

        setNbReceivedEmployees(_nbReceivedEmployees);
        setNbInsertedEmployees(_nbInsertedEmployees);
        setNbUpdatedEmployees(_nbUpdatedEmployees);
      } else {
        setImportResults({
          status: 'ERROR',
          message: 'Import failed, please check the instructions and try again.',
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const exportLogs = () => {
    const ws = XLSX.utils.aoa_to_sheet([HEADERS, ...logsData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'ImportErrors.xlsx');
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {t('import_employees.title')}
      </DialogTitle>

      <DialogContent dividers sx={{ py: 2, border: 'none' }}>
        <Box textAlign="center" maxWidth="sm" marginX="auto">
          {importResults.status === 'ERROR' && (
            <>
              <Iconify icon="material-symbols:error" color={error.main} width={48} />
              <Typography variant="h5" mb={2}>
                Opération échouée
              </Typography>

              <Box mb={2}>
                <Typography color={error.main}>{importResults.message}</Typography>
              </Box>

              <Stack width={1} direction="row" justifyContent="center" gap={2} mt={4} mb={2}>
                <Button variant="outlined" onClick={onClose}>
                  {t('common.close')}
                </Button>
              </Stack>
            </>
          )}

          {(importResults.status === 'SUCCESS' ||
            importResults.status === 'COMPLETED_WITH_ERRORS') && (
            <>
              <Iconify
                icon={
                  importResults.status === 'SUCCESS'
                    ? 'material-symbols:check-circle'
                    : 'material-symbols:error'
                }
                color={importResults.status === 'SUCCESS' ? success.main : error.main}
                width={48}
              />
              <Typography variant="h5" mb={2}>
                {t('import_employees.operation_completed')}
              </Typography>

              <Stack direction="row" justifyContent="center" textAlign="start" mt={2} mb={2}>
                <ul>
                  <li>
                    {t('import_employees.records_received')}: <strong>{nbReceivedEmployees}</strong>
                  </li>
                  <li>
                    {t('import_employees.records_added')}: <strong>{nbInsertedEmployees}</strong>
                  </li>
                  <li>
                    {t('import_employees.records_updated')}: <strong>{nbUpdatedEmployees}</strong>
                  </li>
                </ul>
              </Stack>

              {importResults.status === 'COMPLETED_WITH_ERRORS' && (
                <Box mt={2}>
                  <Typography color={error.main}>{importResults.message}</Typography>
                  {invalidGroups?.length > 0 && (
                    <Typography color={error.main} mt={2}>
                      Les codes de groupe suivants sont invalides : (
                      {invalidGroups?.map((g) => g.code).join(', ')})
                    </Typography>
                  )}
                </Box>
              )}

              <Stack width={1} direction="row" justifyContent="center" gap={2} mt={4} mb={2}>
                {importResults.status === 'COMPLETED_WITH_ERRORS' && (
                  <Button variant="outlined" onClick={exportLogs}>
                    {t('import_employees.download_error_log')}
                  </Button>
                )}
                <Button variant="outlined" onClick={onClose}>
                  {t('common.close')}
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
