// import axios, { fetcher, endpoints } from 'src/utils/axios';
import useSWR from 'swr';
import { useMemo } from 'react';

import axios, { fetcher } from 'src/utils/axios';

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export function useGetHasPermission(route: string) {
  const URL = `/haspermission?route=${route}`;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      isValidating,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function login(values: object) {
  const res = await axios.post('/auth/login', values);

  return res;
}

export async function mfaLogin(values: object) {
  const res = await axios.post('/auth/login_mfa', values);

  return res;
}

export async function selectCompany(values: object) {
  const res = await axios.post('/auth/select-company', values);

  return res;
}

export async function verifyToken(values: object) {
  const res = await axios.post('/auth/verify-token', values);

  return res;
}

export async function hasPermission(route: string | string[]) {
  const res = await axios.post('/haspermission', { route });

  return res;
}

export async function forgotPassword(values: object) {
  const res = await axios.post('/auth/reset', values);

  return res;
}

export async function verifyPasswordToken(values: object) {
  const res = await axios.post('/auth/verify_password_token', values);

  return res;
}

export async function updatePassword(values: object) {
  const res = await axios.post('/auth/update_password', values);

  return res;
}
